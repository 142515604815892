var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-center full-height full-width"},[_c('PageTitle',{attrs:{"icon":"mdi-message-alert","title":_vm.$tc('NavigationForStaff.feedback')},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('v-btn',{attrs:{"color":"cta white--text","block":_vm.$vuetify.breakpoint.xsOnly},on:{"click":function($event){_vm.feedbackDialog = true}}},[_vm._v(" "+_vm._s(_vm.$tc("Feedback.newStaffFeedback"))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"right":""}},[_vm._v(" mdi-plus ")])],1)]},proxy:true}])}),_c('v-row',{class:_vm.feedbacks.length >= 0 && !_vm.loader
        ? 'full-width flex-grow-0'
        : 'full-width flex-grow-1'},[_c('v-col',{staticClass:"d-flex justify-center"},[(_vm.loader)?_c('Loader',{attrs:{"message":_vm.$t('Loader.feedback')}}):(!_vm.loader)?_c('v-card',{staticClass:"custom-card",attrs:{"elevation":"8"}},[_c('bar-search',{attrs:{"search":_vm.search,"readonly":_vm.loadingDT,"searchCallback":_vm.searchFeedbackByText,"label":_vm.$tc('Feedback.searchBar')},on:{"update:search":function($event){_vm.search=$event}}}),_c('v-data-table',{staticClass:"customDT",attrs:{"headers":_vm.headers,"items":_vm.feedbacks,"server-items-length":_vm.pagination.totalItems,"loading":_vm.loadingDT,"footer-props":{
            itemsPerPageText: _vm.$tc('Feedback.feedbacksPerPage'),
            pageText: ("{0}-{1} " + (_vm.$tc('Feedback.feedbacksByPage')) + " {2}"),
            'items-per-page-all-text': _vm.$tc('Feedback.allFeedbacks'),
          },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"info","indeterminate":"","absolute":""}})]},proxy:true},{key:"no-data",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(" "+_vm._s(_vm.$tc("Feedback.emptySearch"))+" ")])]},proxy:true},{key:"loading",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(_vm._s(_vm.$tc("Feedback.loadingSearch")))])]},proxy:true},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))])]}},{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatedDate(item.date))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(item.status.name !== _vm.$constants.STATUS.DELETED)?_c('v-icon',{directives:[{name:"can",rawName:"v-can:delete",value:([_vm.$constants.PRIVILEGES.FEEDBACK]),expression:"[$constants.PRIVILEGES.FEEDBACK]",arg:"delete"}],attrs:{"small":""},on:{"click":function($event){return _vm.deleteFeedbackConfirm(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)})],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 error white--text"},[_vm._v(_vm._s(_vm.$tc("Feedback.deleteTitle")))]),_c('v-card-text',{staticClass:"py-6 text-center"},[_vm._v(" "+_vm._s(_vm.$tc("Feedback.deleteQuestion"))+" ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"same-size-btn mr-2",attrs:{"color":"error"},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$tc("Action.cancel")))]),_c('v-btn',{staticClass:"same-size-btn",attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.deleteFeedback}},[_vm._v(_vm._s(_vm.$tc("Action.delete")))])],1)],1)],1),_c('FeedbackDialog',{attrs:{"feedbackDialog":_vm.feedbackDialog},on:{"closeDialog":_vm.fetchFeedbacks}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }