



















































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { Feedback } from "@/models/feedback.interface";
import FeedbackDialog from "@/components/General/FeedbackDialog.vue";
import { StyleCheck } from "@/mixins/style-check";
import BarSearch from "@/components/Input/BarSearch.vue";
@Component({
  components: { PageTitle, Loader, Empty, FeedbackDialog, BarSearch },
})
export default class Feedbacks extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck
) {
  feedbackDialog = false;
  loader = false;
  loading = false;
  loadingDT = false;
  dialogDelete = false;
  search = "";
  options = {};
  feedbackID: string | undefined = "";

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async searchFeedbackByText() {
    this.loadingDT = true;

    await this.getFeedbacks(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getFeedbacks(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  private async getFeedbacks(
    page: number,
    size: number,
    text = ""
  ): Promise<void> {
    await this.$store
      .dispatch("feedback/getFeedbacks", { page, size, text })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Feedback.fetchError.get"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getFeedbacks(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search
    );
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Feedback.headers.feedback"),
        value: "text",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Feedback.headers.date"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Feedback.headers.status"),
        value: "status",
        align: "center",
        sortable: false,
      },

      ...(this.$perms.check({
        actions: "D",
        entities: [this.$constants.PRIVILEGES.FEEDBACK],
      })
        ? [
            {
              text: this.$tc("Feedback.headers.actions"),
              value: "actions",
              sortable: false,
              align: "center",
            },
          ]
        : []),
    ];
  }

  private get feedbacks(): Feedback[] {
    let response = this.$store.getters["feedback/getFeedbacks"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.feedbacks) {
      return response.feedbacks;
    } else {
      return [];
    }
  }

  private async deleteFeedbackConfirm(feedback: Feedback) {
    this.feedbackID = feedback.id;
    this.dialogDelete = true;
  }

  private async deleteFeedback() {
    this.loading = true;
    await this.$store
      .dispatch("feedback/deleteFeedback", {
        id: this.feedbackID,
        status: this.$constants.STATUS.DELETED,
      })
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("Feedback.deleteSuccess"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.getFeedbacks(
          this.pagination.page,
          this.pagination.itemsPerPage == -1
            ? this.pagination.totalItems
            : this.pagination.itemsPerPage,
          this.search
        );
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Feedback.deleteError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.closeDelete();
        this.loading = false;
      });
  }

  private closeDelete() {
    this.feedbackID = "";
    this.dialogDelete = false;
  }

  private async fetchFeedbacks() {
    this.feedbackDialog = false;
    this.loadingDT = true;
    await this.getFeedbacks(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }
}
